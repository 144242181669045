// src/TikTokFoodUI.jsx
import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import Confetti from 'react-confetti'; // Asegúrate de haber instalado react-confetti
import {
  MdOutlineFastfood
} from "react-icons/md";
import {
  AiFillHeart,
  AiOutlineHeart,
  AiFillPlusCircle,
  AiOutlinePlusCircle
} from "react-icons/ai";
import { BsList } from "react-icons/bs";
import { Icon } from '@iconify/react'; // Importación de Iconify
import PropTypes from 'prop-types'; // Importación de PropTypes

// 1. Componente personalizado para el icono de "Desserts"
const DessertIcon = ({ className }) => (
  <Icon icon="ep:dessert" className={className} />
);

DessertIcon.propTypes = {
  className: PropTypes.string,
};

// 2. Componente personalizado para el icono de "Burgers"
const BowlSaladIcon = ({ className }) => (
  <Icon icon="fluent:bowl-salad-20-filled" className={className} />
);

BowlSaladIcon.propTypes = {
  className: PropTypes.string,
};

// 3. Componente personalizado para el icono de "Bakery"
const ColdDrinkIcon = ({ className }) => (
  <Icon icon="ep:cold-drink" className={className} />
);

ColdDrinkIcon.propTypes = {
  className: PropTypes.string,
};

// 4. Componente personalizado para el icono de "Noodles"
const CutOfMeatIcon = ({ className }) => (
  <Icon icon="fluent-emoji-high-contrast:cut-of-meat" className={className} />
);

CutOfMeatIcon.propTypes = {
  className: PropTypes.string,
};

// 5. Componente personalizado para el icono de "Compartir"
const ShareIcon = ({ className }) => (
  <Icon icon="ic:baseline-ios-share" className={className} />
);

ShareIcon.propTypes = {
  className: PropTypes.string,
};

// 6. Componente personalizado para el icono de "Copas"
const ChampagnePartyAlcoholIcon = ({ className }) => (
  <Icon icon="streamline:champagne-party-alcohol" className={className} />
);

ChampagnePartyAlcoholIcon.propTypes = {
  className: PropTypes.string,
};

// 7. Componente personalizado para el icono de "Restaurante"
const RestaurantIcon = ({ className }) => (
  <Icon icon="material-symbols:restaurant" className={`${className}`} style={{ color: "white" }} />
);

RestaurantIcon.propTypes = {
  className: PropTypes.string,
};

// Definir un componente separado para los posts
const PostComponent = ({
  post,
  expandedPost,
  handleExpand,
  translations,
  selectedLanguage,
  toggleFavorite,
  favorites,
  addToOrders,
  orders,
  navbarHeight,
  activeSection
}) => {
  const infoVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: { y: 0, opacity: 1 },
    exit: { y: 20, opacity: 0 }
  };

  const postVariants = {
    hidden: { opacity: 0, scale: 0.9 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: { duration: 0.5, ease: "easeOut" }
    },
    exit: {
      opacity: 0,
      scale: 0.9,
      transition: { duration: 0.3 }
    }
  };

  const buttonVariants = {
    hover: {
      scale: 1.1,
      transition: { type: "spring", stiffness: 400, damping: 10 }
    },
    tap: { scale: 0.95 }
  };

  return (
    <motion.div
      key={`${activeSection}-${post.id}`}
      id={`post-${activeSection}-${post.id}`}
      variants={postVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="snap-start snap-always relative overflow-visible"
      style={{ height: `calc(100vh - ${navbarHeight}px)` }}
    >
      {/* Video o imagen de fondo */}
      <motion.div
        className="absolute inset-0"
      >
        {post.video ? (
          <motion.video
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            src={post.video}
            className="w-full h-full object-cover pointer-events-none" // Agregado pointer-events-none
            autoPlay
            loop
            muted
            playsInline // Agregado playsInline
            preload="auto" // Opcional: mejora la carga del video
          />
        ) : (
          <motion.img
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            src={`https://${post.image}`}
            alt={post.title}
            className="w-full h-full object-cover"
            loading="lazy"
          />
        )}
        <div className="absolute inset-0 bg-gradient-to-b from-transparent via-black/40 to-black/80 pointer-events-none" /> {/* Agregado pointer-events-none */}

        {/* Capa oscura para mejorar la legibilidad cuando está expandido */}
        <AnimatePresence>
          {expandedPost === post.id && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 0.5 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
              className="absolute inset-0 bg-black/50 pointer-events-none" // Agregado pointer-events-none
              style={{ zIndex: 10 }}
            />
          )}
        </AnimatePresence>
      </motion.div>

      {/* Botones de acciones */}
      <div
        className="absolute flex flex-col gap-4 z-30"
        style={{
          bottom: `${navbarHeight + 40}px`,
          right: "16px"
        }}
      >
        <motion.button
          whileHover={buttonVariants.hover}
          whileTap={buttonVariants.tap}
          className="p-3 backdrop-blur-sm bg-black/50 rounded-full hover:bg-amber-500/50 transition-all duration-300"
          onClick={() => toggleFavorite(post)}
        >
          <motion.div
            animate={{
              scale: favorites.find((item) => item.id === post.id)
                ? [1, 1.2, 1]
                : 1
            }}
            transition={{ duration: 0.2 }}
          >
            {favorites.find((item) => item.id === post.id) ? (
              <AiFillHeart className="text-3xl text-amber-500" />
            ) : (
              <AiOutlineHeart className="text-3xl" />
            )}
          </motion.div>
        </motion.button>

        <motion.button
          whileHover={buttonVariants.hover}
          whileTap={buttonVariants.tap}
          className="p-3 backdrop-blur-sm bg-black/50 rounded-full hover:bg-amber-500/50 transition-all duration-300"
          onClick={() => addToOrders(post)}
        >
          <motion.div
            animate={{
              scale: orders.find((item) => item.id === post.id)
                ? [1, 1.2, 1]
                : 1
            }}
            transition={{ duration: 0.2 }}
          >
            {orders.find((item) => item.id === post.id) ? (
              <AiFillPlusCircle className="text-3xl text-amber-500" />
            ) : (
              <AiOutlinePlusCircle className="text-3xl" />
            )}
          </motion.div>
        </motion.button>

        <motion.button
          whileHover={buttonVariants.hover}
          whileTap={buttonVariants.tap}
          className="p-3 backdrop-blur-sm bg-black/50 rounded-full hover:bg-amber-500/50 transition-all duration-300 flex items-center justify-center text-gray-400 hover:text-white"
        >
          <ShareIcon className="text-3xl" />
        </motion.button>
      </div>

      {/* Información del post */}
      <div
        className="absolute left-0 right-0 z-20 px-4"
        style={{
          bottom: `${navbarHeight + 40}px`,
          padding: "0 1rem",
          paddingRight: "70px"
        }}
      >
        {/* Contenido */}
        <div>
          {/* Título y precio */}
          <motion.h2
            className="text-xl font-bold mt-2 mb-1"
            initial={{ x: -20, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ delay: 0.2 }}
          >
            {post.title}
          </motion.h2>
          <motion.span
            className="text-lg font-bold text-amber-500 mb-2 block"
            initial={{ x: -20, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ delay: 0.3 }}
          >
            {post.price}
          </motion.span>

          {/* Autor */}
          <motion.p
            className="text-gray-200 text-sm mb-1"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.4 }}
          >
            @{post.author}
          </motion.p>

          {/* Descripción y contenido expandido */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5 }}
          >
            <p className="text-gray-200 text-base mb-2">
              {expandedPost === post.id
                ? post.longDescription
                : post.description}
            </p>

            {/* Sección expandida */}
            <AnimatePresence>
              {expandedPost === post.id && (
                <motion.div
                  key="extra"
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  variants={infoVariants}
                  transition={{ duration: 0.5, ease: "easeInOut" }}
                  className="mb-4"
                >
                  {/* Información adicional */}
                  <p className="text-gray-200 text-sm mb-2">
                    {post.extraInfo}
                  </p>

                  {/* Alérgenos */}
                  <div>
                    <h3 className="font-bold mb-1">Alérgenos:</h3>
                    <ul className="flex flex-wrap gap-4">
                      {post.allergens.map((allergen, index) => (
                        <li key={index} className="flex items-center gap-2">
                          <img
                            src={allergen.icon}
                            alt={allergen.name}
                            className="w-6 h-6"
                          />
                          <span>{allergen.name}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>

            {/* Botón Ver Más / Ver Menos */}
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => handleExpand(post.id)}
              className="text-amber-500 hover:text-amber-400 font-medium mt-2 mb-4"
            >
              {expandedPost === post.id
                ? translations[selectedLanguage].seeLess
                : translations[selectedLanguage].seeMore}
            </motion.button>
          </motion.div>
        </div>
      </div>
    </motion.div>
  );
};

PostComponent.propTypes = {
  post: PropTypes.object.isRequired,
  expandedPost: PropTypes.number,
  handleExpand: PropTypes.func.isRequired,
  translations: PropTypes.object.isRequired,
  selectedLanguage: PropTypes.string.isRequired,
  toggleFavorite: PropTypes.func.isRequired,
  favorites: PropTypes.array.isRequired,
  addToOrders: PropTypes.func.isRequired,
  orders: PropTypes.array.isRequired,
  navbarHeight: PropTypes.number.isRequired,
  activeSection: PropTypes.string.isRequired
};

const TikTokFoodUI = () => {
  const [showIntro, setShowIntro] = useState(true);
  const [expandedPost, setExpandedPost] = useState(null);
  const [activeTab, setActiveTab] = useState("all");
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const [currentPostIndex, setCurrentPostIndex] = useState(0);
  const [showLanguageModal, setShowLanguageModal] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("es");
  const [favorites, setFavorites] = useState([]);
  const [orders, setOrders] = useState([]);
  const [scrollY, setScrollY] = useState(0);
  const [showListModal, setShowListModal] = useState(false);
  const [activeSection, setActiveSection] = useState("restaurante"); // Nuevo estado para la sección activa

  const navbarHeight = 60;

  const navItems = [
    { icon: MdOutlineFastfood, label: "All", id: "all" },
    { icon: BowlSaladIcon, label: "Burgers", id: "burgers" },
    { icon: DessertIcon, label: "Desserts", id: "desserts" },
    { icon: ColdDrinkIcon, label: "Bakery", id: "bakery" },
    { icon: CutOfMeatIcon, label: "Noodles", id: "noodles" }
  ];

  const languages = [
    { code: "es", name: "Spanish", flag: "/img/spain.svg" },
    { code: "en", name: "English", flag: "/img/usa.svg" },
    { code: "cn", name: "Chinese", flag: "/img/china.svg" },
    { code: "ru", name: "Russian", flag: "/img/russia.svg" },
    { code: "pk", name: "Pakistani", flag: "/img/pakistan.svg" } // Cambiado de 'ar' a 'pk' para alinear con 'pakistan.svg'
  ];

  const translations = {
    es: {
      title: "Entrantes",
      seeMore: "Ver Más",
      seeLess: "Ver Menos",
      ingredients: "Ingredientes",
      cookingTime: "Tiempo de Cocción",
      prepTime: "Tiempo de Preparación",
      difficulty: "Dificultad",
      shareRecipe: "Compartir Receta",
      all: "Todos",
      burgers: "Hamburguesas",
      desserts: "Postres",
      bakery: "Panadería",
      noodles: "Fideos",
      selectLanguage: "Seleccionar idioma",
      list: "Lista",
      leaveReview: "¡Déjanos una reseña en Google!",
      goToReview: "Ir a Reseñas",
      close: "Cerrar",
      reviewConfirmationTitle: "¡Perfecto, tu 10% de descuento está listo!",
      reviewConfirmationMessage: "Gracias por tu reseña. Presenta el siguiente código QR a tu mesero para aplicar el descuento.",
      reviewConfirmationButton: "✕ Cerrar",
      discountCode: "DESCUENTO10",
      uploadReviewTitle: "Sube tu captura de la reseña",
      uploadReviewMessage: "Por favor, sube una captura de pantalla de tu reseña positiva en Google para recibir tu descuento.",
      uploadButton: "Subir Captura",
      submitButton: "Enviar Captura",
      uploadSuccessMessage: "¡Captura subida exitosamente! Tu descuento ya está disponible.",
      uploadErrorMessage: "Hubo un error al subir la captura. Por favor, intenta nuevamente.",
    },
    en: {
      title: "Starters",
      seeMore: "See More",
      seeLess: "See Less",
      ingredients: "Ingredients",
      cookingTime: "Cooking Time",
      prepTime: "Prep Time",
      difficulty: "Difficulty",
      shareRecipe: "Share Recipe",
      all: "All",
      burgers: "Burgers",
      desserts: "Desserts",
      bakery: "Bakery",
      noodles: "Noodles",
      selectLanguage: "Select Language",
      list: "List",
      leaveReview: "Leave us a review on Google!",
      goToReview: "Go to Reviews",
      close: "Close",
      reviewConfirmationTitle: "Perfect, your 10% discount is ready!",
      reviewConfirmationMessage: "Thank you for your review. Present the following QR code to your waiter to apply the discount.",
      reviewConfirmationButton: "✕ Close",
      discountCode: "DISCOUNT10",
      uploadReviewTitle: "Upload your review screenshot",
      uploadReviewMessage: "Please upload a screenshot of your positive Google review to receive your discount.",
      uploadButton: "Upload Screenshot",
      submitButton: "Submit Screenshot",
      uploadSuccessMessage: "Screenshot uploaded successfully! Your discount is now available.",
      uploadErrorMessage: "There was an error uploading the screenshot. Please try again.",
    },
    // ... otros idiomas
  };

  // Definir posts para la sección de Restaurante
  const postsRestaurante = [
    {
      id: 1,
      title: "Merluza a la Romana",
      author: "lareservamadridclub",
      likes: "125K",
      description: "Merluza dorada con alioli de cebollino.",
      longDescription:
        "Merluza dorada con alioli de cebollino.",
      price: "16€",
      extraInfo: "MEDIA RACIÓN (16€)",
      allergens: [
        { name: "Cereales con gluten", icon: "/img/1.svg" },
        { name: "Huevos", icon: "/img/2.svg" },
        { name: "Pescado", icon: "/img/3.svg" },
        { name: "Vino", icon: "/img/4.svg" },
      ],
      video: "/video/plato1.mp4"
    },
    // ... otros posts de restaurante
  ];

  // Definir posts para la sección de Discoteca
  const postsDiscoteca = [
    {
      id: 1,
      title: "Cóctel de Fiesta",
      author: "discotecafiestamadrid",
      likes: "200K",
      description: "Cóctel vibrante con sabores intensos.",
      longDescription:
        "Cóctel vibrante con sabores intensos, perfecto para una noche de fiesta.",
      price: "12€",
      extraInfo: "TAMAÑO GRANDE (12€)",
      allergens: [
        { name: "Frutas", icon: "/img/frutas.svg" },
        { name: "Alcohol", icon: "/img/alcohol.svg" },
      ],
      video: "/video/cocktail.mp4"
    },
    // ... otros posts de discoteca
  ];

  // Variantes para animaciones con Framer Motion
  const postVariants = {
    hidden: { opacity: 0, scale: 0.9 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: { duration: 0.5, ease: "easeOut" }
    },
    exit: {
      opacity: 0,
      scale: 0.9,
      transition: { duration: 0.3 }
    }
  };

  const buttonVariants = {
    hover: {
      scale: 1.1,
      transition: { type: "spring", stiffness: 400, damping: 10 }
    },
    tap: { scale: 0.95 }
  };

  const modalVariants = {
    hidden: { opacity: 0, y: 50, scale: 0.95 },
    visible: {
      opacity: 1,
      y: 0,
      scale: 1,
      transition: { type: "spring", stiffness: 300, damping: 30 }
    }
  };

  const infoVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: { y: 0, opacity: 1 },
    exit: { y: 20, opacity: 0 }
  };

  const minSwipeDistance = 50;

  // Manejo de gestos táctiles
  const onTouchStartHandler = (e) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientY);
  };

  const onTouchMoveHandler = (e) => {
    setTouchEnd(e.targetTouches[0].clientY);
  };

  const onTouchEndHandler = () => {
    if (!touchStart || touchEnd === null) return;

    const distance = touchStart - touchEnd;
    const isUpSwipe = distance > minSwipeDistance;
    const isDownSwipe = distance < -minSwipeDistance;

    if (isUpSwipe && currentPostIndex < (activeSection === "restaurante" ? postsRestaurante.length : postsDiscoteca.length) - 1) {
      setCurrentPostIndex(currentPostIndex + 1);
    }
    if (isDownSwipe && currentPostIndex > 0) {
      setCurrentPostIndex(currentPostIndex - 1);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleExpand = (postId) => {
    setExpandedPost(expandedPost === postId ? null : postId);
  };

  const toggleFavorite = (post) => {
    setFavorites((prev) => {
      const exists = prev.find((item) => item.id === post.id);
      if (exists) {
        return prev.filter((item) => item.id !== post.id);
      }
      return [...prev, post];
    });
  };

  const addToOrders = (post) => {
    setOrders((prev) => {
      const exists = prev.find((item) => item.id === post.id);
      if (!exists) {
        return [...prev, post];
      }
      return prev;
    });
  };

  useEffect(() => {
    const element = document.getElementById(`post-${activeSection}-${currentPostIndex}`);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, [currentPostIndex, activeSection]);

  const handleLanguageChange = (langCode) => {
    setSelectedLanguage(langCode);
    setShowLanguageModal(false);
  };

  // URL de la página de reseñas de Google (puedes eliminarla si ya no es necesaria)
  const googleReviewUrl = "https://www.google.com/search?cs=1&hl=es-ES&sca_esv=347e7d2a4085ca22&output=search&tbm=lcl&kgmid=/g/11p01llyd4&q=La+Reserva+Club&shndl=30&source=sh/x/kp/local/m1/1&kgs=1263fe0f73ee18f6"; // Reemplaza con la URL correcta de reseñas

  return (
    <div className="h-screen overflow-y-scroll snap-y snap-mandatory bg-black text-white relative">
      {/* Mostrar el video de portada si showIntro es true */}
      {showIntro ? (
        <div className="h-full w-full flex items-center justify-center">
          <video
            src="/video/portada.mp4"
            className="w-full h-full object-cover pointer-events-none" // Agregado pointer-events-none
            autoPlay
            muted
            playsInline // Agregado playsInline
            preload="auto" // Opcional: mejora la carga del video
            onEnded={() => setShowIntro(false)}
          />
        </div>
      ) : (
        // Mostrar la aplicación principal cuando showIntro es false
        <>
          {/* Header */}
          <motion.header
            initial={{ y: -100 }}
            animate={{ y: 0 }}
            transition={{ type: "spring", stiffness: 300, damping: 30 }}
            className="fixed top-0 left-0 right-0 z-50 backdrop-blur-md bg-gradient-to-b from-black/80 via-black/60 to-transparent py-4"
          >
            <h1 className="text-2xl font-bold text-center text-white tracking-wider">
              {translations[selectedLanguage].title}
            </h1>
          </motion.header>

          {/* Botones de lista, idioma y copas */}
          <div className="fixed top-4 right-4 z-50 flex flex-col gap-4">
            {/* Botón de Copas */}
            <motion.button
              whileHover={buttonVariants.hover}
              whileTap={buttonVariants.tap}
              className="p-3 backdrop-blur-sm bg-black/50 rounded-full hover:bg-amber-500/50 transition-colors text-3xl z-30 flex items-center justify-center pointer-events-auto"
              onClick={() => setActiveSection(prev => prev === "restaurante" ? "discoteca" : "restaurante")}
            >
              {activeSection === "restaurante" ? (
                <ChampagnePartyAlcoholIcon className="w-8 h-8" />
              ) : (
                <RestaurantIcon className="w-8 h-8" />
              )}
            </motion.button>

            {/* Botón de Idioma (Banderas) */}
            <motion.button
              whileHover={buttonVariants.hover}
              whileTap={buttonVariants.tap}
              className="p-3 backdrop-blur-sm bg-black/50 rounded-full hover:bg-amber-500/50 transition-colors text-2xl z-30 flex items-center justify-center pointer-events-auto"
              onClick={() => setShowLanguageModal(true)}
            >
              {languages.find((lang) => lang.code === selectedLanguage)?.flag && (
                <img
                  src={languages.find((lang) => lang.code === selectedLanguage).flag}
                  alt={`${languages.find((lang) => lang.code === selectedLanguage).name} flag`}
                  className="w-6 h-6"
                />
              )}
            </motion.button>
          </div>

          {/* Modal de selección de idioma */}
          <AnimatePresence>
            {showLanguageModal && (
              <motion.div
                initial="hidden"
                animate="visible"
                exit="hidden"
                variants={modalVariants}
                className="fixed inset-0 bg-black/90 z-50 flex items-center justify-center backdrop-blur-sm"
              >
                <div className="bg-gray-900/90 backdrop-blur-md p-6 rounded-lg w-full max-w-md">
                  <h2 className="text-xl font-bold mb-4 text-center">
                    {translations[selectedLanguage].selectLanguage}
                  </h2>
                  <div className="grid gap-2">
                    {languages.map((language) => (
                      <motion.button
                        key={language.code}
                        whileHover={{ scale: 1.02 }}
                        whileTap={{ scale: 0.98 }}
                        className={`w-full text-left px-4 py-3 rounded-lg flex items-center ${
                          selectedLanguage === language.code
                            ? "bg-amber-500"
                            : "bg-gray-800"
                        } hover:bg-amber-600 transition-colors`}
                        onClick={() => handleLanguageChange(language.code)}
                      >
                        {language.flag && (
                          <img
                            src={language.flag}
                            alt={`${language.name} flag`}
                            className="w-6 h-6 mr-3"
                          />
                        )}
                        <span className="text-lg">{language.name}</span>
                      </motion.button>
                    ))}
                  </div>
                  <motion.button
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    className="mt-4 w-full py-2 bg-gray-800 rounded-lg hover:bg-gray-700 transition-colors"
                    onClick={() => setShowLanguageModal(false)}
                  >
                    ✕ {translations[selectedLanguage].close}
                  </motion.button>
                </div>
              </motion.div>
            )}
          </AnimatePresence>

          {/* Modal de Lista */}
          <AnimatePresence>
            {showListModal && (
              <motion.div
                initial="hidden"
                animate="visible"
                exit="hidden"
                variants={modalVariants}
                className="fixed inset-0 bg-black/90 z-50 flex items-center justify-center backdrop-blur-sm"
              >
                <div className="bg-gray-900/90 backdrop-blur-md p-6 rounded-lg w-full max-w-md">
                  <h2 className="text-xl font-bold mb-4 text-center">
                    {translations[selectedLanguage].list}
                  </h2>
                  {/* Aquí puedes agregar el contenido del modal de lista */}
                  <motion.button
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    className="mt-4 w-full py-2 bg-gray-800 rounded-lg hover:bg-gray-700 transition-colors"
                    onClick={() => setShowListModal(false)}
                  >
                    ✕ {translations[selectedLanguage].close}
                  </motion.button>
                </div>
              </motion.div>
            )}
          </AnimatePresence>

          {/* Video de Fondo Condicional */}
          <AnimatePresence>
            <motion.div
              key={activeSection} // Esto fuerza a React a tratar cada video como un componente distinto
              className="absolute inset-0 z-0"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 1 }}
            >
              <motion.video
                src={activeSection === "restaurante" ? "/video/plato1.MP4" : "/video/fiesta.mp4"}
                className="w-full h-full object-cover pointer-events-none" // Agregado pointer-events-none
                autoPlay
                loop
                muted
                playsInline // Agregado playsInline
                preload="auto" // Opcional: mejora la carga del video
              />
              <div className="absolute inset-0 bg-gradient-to-b from-transparent via-black/40 to-black/80 pointer-events-none" /> {/* Agregado pointer-events-none */}
            </motion.div>
          </AnimatePresence>

          {/* Contenido principal */}
          <div
            className="pt-16"
            onTouchStart={onTouchStartHandler}
            onTouchMove={onTouchMoveHandler}
            onTouchEnd={onTouchEndHandler}
          >
            <AnimatePresence>
              {activeSection === "restaurante" ? (
                postsRestaurante.map((post, index) => (
                  <PostComponent
                    key={`restaurante-${post.id}`}
                    post={post}
                    expandedPost={expandedPost}
                    handleExpand={handleExpand}
                    translations={translations}
                    selectedLanguage={selectedLanguage}
                    toggleFavorite={toggleFavorite}
                    favorites={favorites}
                    addToOrders={addToOrders}
                    orders={orders}
                    navbarHeight={navbarHeight}
                    activeSection={activeSection}
                  />
                ))
              ) : (
                postsDiscoteca.map((post, index) => (
                  <PostComponent
                    key={`discoteca-${post.id}`}
                    post={post}
                    expandedPost={expandedPost}
                    handleExpand={handleExpand}
                    translations={translations}
                    selectedLanguage={selectedLanguage}
                    toggleFavorite={toggleFavorite}
                    favorites={favorites}
                    addToOrders={addToOrders}
                    orders={orders}
                    navbarHeight={navbarHeight}
                    activeSection={activeSection}
                  />
                ))
              )}
            </AnimatePresence>
          </div>

          {/* Navegación inferior */}
          <motion.nav
            initial={{ y: 100 }}
            animate={{ y: 0 }}
            transition={{ type: "spring", stiffness: 300, damping: 30 }}
            className="fixed bottom-0 left-0 right-0 backdrop-blur-md bg-gradient-to-t from-black/90 to-transparent px-4 py-2 z-50"
          >
            <div className="max-w-screen-xl mx-auto relative">
              <ul className="flex justify-around items-center">
                {navItems.map((item) => {
                  const IconComponent = item.icon;
                  return (
                    <motion.li key={item.id}>
                      <motion.button
                        whileHover={buttonVariants.hover}
                        whileTap={buttonVariants.tap}
                        className={`flex items-center p-2 focus:outline-none focus:ring-amber-500 rounded-lg ${
                          activeTab === item.id
                            ? "text-amber-500"
                            : "text-gray-400 hover:text-white"
                        }`}
                        onClick={() => setActiveTab(item.id)}
                        aria-label={translations[selectedLanguage][item.id]}
                      >
                        <IconComponent className="text-3xl" />
                      </motion.button>
                    </motion.li>
                  );
                })}
              </ul>
            </div>
          </motion.nav>
        </>
      )}
    </div>
  );
};

export default TikTokFoodUI;
