import React from 'react';
import SignInForm from './TikTokFoodUI';

function App() {
  return (
    <div className="App">
      <SignInForm />
    </div>
  );
}

export default App;
